import { lazy } from "react";

import { ValuationProcessStep } from "~/common/state/valuationProcessState";
import { SiteConfiguration } from "./domain/SiteConfiguration";
import { internalNavigationButtons } from "./internalNavigationButtons";

const SessionsHeader = lazy(
  () => import("~/sessions/components/SessionsHeader"),
);

const BoundariesHeader = lazy(
  () => import("~/boundaries/components/Header/BoundariesHeader"),
);
const CompRecommendationHeader = lazy(
  () =>
    import("~/compRecommendation/components/Header/CompRecommendationHeader"),
);

export default {
  header: {
    icon: (
      <img
        src="/skin/logo_truetracts.png"
        alt="TrueTracts"
        style={{ maxWidth: "200px", height: "auto" }}
      />
    ),
    title: "TrueTracts",
    components: {
      [ValuationProcessStep.Sessions]: <SessionsHeader />,
      [ValuationProcessStep.Boundaries]: <BoundariesHeader />,
      [ValuationProcessStep.MarketAnalysis]: null,
      [ValuationProcessStep.PreliminaryAdjustments]: null,
      [ValuationProcessStep.CompRecommendation]: <CompRecommendationHeader />,
      [ValuationProcessStep.CompRec]: null,
      [ValuationProcessStep.Adjustments]: null,
      [ValuationProcessStep.Reconciliation]: null,
      [ValuationProcessStep.Export]: null,
    },
    userSettingsEnabled: true,
  },
  navBar: {
    buttons: internalNavigationButtons,
  },
  body: {
    componentOverrides: {
      [ValuationProcessStep.Sessions]: null,
      [ValuationProcessStep.Boundaries]: null,
      [ValuationProcessStep.MarketAnalysis]: null,
      [ValuationProcessStep.PreliminaryAdjustments]: null,
      [ValuationProcessStep.CompRecommendation]: null,
      [ValuationProcessStep.CompRec]: null,
      [ValuationProcessStep.Adjustments]: null,
      [ValuationProcessStep.Reconciliation]: null,
      [ValuationProcessStep.Export]: null,
    },
  },
  modules: {
    boundaries: {
      showExportButton: false,
      showZipcodeOverlay: false,
      statistics: {
        showResidualData: true,
        featureExclusionList: [],
      },
      heatmap: {
        resolution: 200,
        filterFactor: 6,
        desiredHomeSales: 6000,
      },
    },
  },
} satisfies Readonly<SiteConfiguration>;
