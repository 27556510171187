import {
  Balance,
  Calculate,
  Download,
  FormatListNumbered,
  LocationOn,
  QueryStats,
} from "@mui/icons-material";

import { ValuationProcessStep } from "~/common/state/valuationProcessState";

export const internalNavigationButtons = [
  {
    step: ValuationProcessStep.Boundaries,
    name: "Market Definition",
    icon: <LocationOn />,
    isDisabled: false,
    path: "market-definition",
  },
  {
    step: ValuationProcessStep.MarketAnalysis,
    name: "Market Analysis",
    icon: <QueryStats />,
    isDisabled: false,
    path: "market-analysis",
  },
  {
    step: ValuationProcessStep.PreliminaryAdjustments,
    name: "Preliminary Adjustments",
    icon: <Calculate />,
    isDisabled: false,
    path: "preliminary-adjustments",
  },
  {
    step: ValuationProcessStep.CompRecommendation,
    name: "Comp Recommendation",
    icon: <FormatListNumbered />,
    isDisabled: false,
    path: "comp-recommendation",
  },
  {
    step: ValuationProcessStep.Adjustments,
    name: "Adjustments",
    icon: <Calculate />,
    isDisabled: false,
    path: "adjustments",
  },
  {
    step: ValuationProcessStep.Reconciliation,
    name: "Reconciliation",
    icon: <Balance />,
    isDisabled: false,
    path: "reconciliation",
  },
  {
    step: ValuationProcessStep.Export,
    name: "Grid Export",
    icon: <Download />,
    isDisabled: false,
    path: "export",
  },
];
